import React from "react";

const Hive2 = ({ color }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15286 6.42173C8.67266 6.40744 10.1925 6.47892 11.6978 6.59328C12.0886 6.62187 12.2768 6.82201 12.2768 7.22229C12.2768 7.62257 12.0886 7.80841 11.6978 7.837C9.38191 8.00855 7.08049 8.06573 4.7646 7.95137C4.02641 7.92278 3.30269 7.87989 2.5645 7.82271C2.17369 7.79411 2 7.59398 2 7.1937C2 6.80772 2.18817 6.62187 2.5645 6.59328C4.09878 6.47892 5.61858 6.40744 7.15286 6.42173Z"
        fill="#333333"
      />
      <path
        d="M7.63034 9.83898C7.35533 9.83898 7.08032 9.83898 6.80531 9.83898C6.61714 9.83898 5.51709 9.79609 5.35787 9.7818C4.56179 9.72461 3.7657 9.62454 2.96961 9.48159C2.6946 9.4387 2.50643 9.28145 2.39064 9.02413C2.20247 8.55237 2.47748 8.19498 2.96961 8.23787C5.01049 8.43801 7.05137 8.4666 9.09225 8.39512C9.78702 8.36653 10.4818 8.30935 11.1766 8.25216C11.6397 8.20928 11.8713 8.30934 11.9292 8.62385C12.0016 9.00983 11.77 9.39581 11.3647 9.46729C10.8581 9.56736 10.3515 9.63884 9.84492 9.69602C9.09225 9.7818 8.36853 9.83898 7.63034 9.83898Z"
        fill="#333333"
      />
      <path
        d="M6.2555 5.99479C5.19887 6.02338 4.14224 6.06626 3.08562 6.16633C2.66586 6.20922 2.43427 6.12345 2.3619 5.83753C2.27506 5.48014 2.44875 5.10846 2.81061 4.99409C3.0422 4.90832 3.28826 4.87973 3.53432 4.85114C6.05286 4.49375 8.57139 4.49375 11.0754 4.90832C11.6689 5.00839 11.9439 5.25141 11.9294 5.70887C11.915 6.02338 11.7268 6.22352 11.3939 6.20922C11.0031 6.18063 10.6123 6.15204 10.2215 6.10915C10.1636 6.09486 7.16738 5.9662 6.2555 5.99479Z"
        fill="#333333"
      />
      <path
        d="M10.5248 4.46546C9.58399 4.3511 8.58526 4.26532 7.58653 4.25103C6.38517 4.23673 5.1838 4.29391 3.9969 4.43687C3.47583 4.49405 3.33108 4.42258 3.28766 4.15096C3.22976 3.80786 3.51925 3.43618 3.91006 3.3647C4.5035 3.25033 5.09695 3.19315 5.70487 3.16456C7.25362 3.07879 8.81685 3.10738 10.3656 3.3647C10.6985 3.42188 10.9591 3.66491 11.0169 3.96512C11.0604 4.27962 10.9012 4.47976 10.5248 4.46546Z"
        fill="#333333"
      />
      <path
        d="M6.97793 10.1705C8.13587 10.199 9.30829 10.1276 10.4662 9.97031C10.6544 9.94172 10.8426 9.94172 10.9584 10.1133C11.0886 10.2848 11.0452 10.4707 10.9584 10.6422C10.8426 10.8709 10.6544 11.0282 10.3939 11.0711C8.83064 11.3141 7.26741 11.357 5.68971 11.2569C5.11074 11.214 4.53176 11.1711 3.95279 11.0711C3.57646 11.0139 3.30145 10.728 3.28697 10.3992C3.2725 10.1133 3.44619 9.92743 3.73568 9.95602C3.99621 9.98461 4.24228 10.0132 4.50282 10.0418C4.7344 10.0704 6.58712 10.1562 6.97793 10.1705Z"
        fill="#333333"
      />
      <path
        d="M6.77622 2.01151C7.55783 1.99721 8.22365 1.98292 8.875 2.15447C9.12106 2.21165 9.35265 2.32601 9.55529 2.49756C9.67108 2.59763 9.71451 2.712 9.67108 2.84066C9.61319 2.98361 9.49739 3.0265 9.35265 3.0122C9.03421 2.98361 8.7013 2.95502 8.38287 2.92643C7.10913 2.84066 5.83539 2.88354 4.57612 3.0265C4.38795 3.04079 4.19979 3.06939 4.12742 2.84066C4.05505 2.62622 4.21426 2.51186 4.35901 2.41179C4.72086 2.16876 5.12615 2.08299 5.5459 2.0544C5.9946 2.01151 6.44331 1.98292 6.77622 2.01151Z"
        fill="#333333"
      />
      <path
        d="M7.15296 12.4843C6.50162 12.4986 5.8358 12.5415 5.18445 12.37C4.93839 12.2985 4.69233 12.2127 4.50416 12.0412C4.38837 11.9411 4.316 11.8267 4.37389 11.6695C4.44626 11.4979 4.59101 11.4693 4.75023 11.4979C5.67658 11.6123 6.61741 11.6552 7.54377 11.6409C8.19512 11.6266 8.83199 11.5837 9.48333 11.5122C9.65702 11.4979 9.81624 11.4979 9.90309 11.6695C9.98993 11.8553 9.87414 11.9697 9.74387 12.0841C9.35306 12.3843 8.90436 12.47 8.4267 12.4986C8.00695 12.4986 7.57272 12.4843 7.15296 12.4843Z"
        fill="#333333"
      />
    </svg>
  );
};
export default Hive2;
